<template>
<div>
  <game-list-add-new
      :is-add-new-game-sidebar-active.sync="isAddNewGameSidebarActive"
      @refetch-data="refetchData"
    />
  <b-card
  no-body
  class="mb-50"
  >
  <div class="m-1">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-button
                id="id-add-new-game"
                disabled
                variant="primary"
                @click="isAddNewGameSidebarActive = true"
              >
                <span class="text-nowrap">Add New Game (disabled)</span>
              </b-button>
              
          </div>
        </b-col>
      </b-row>

    </div>
  </b-card>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <b-table
      ref="refGameListTable"
      :items="fetchGames"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy.sync="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
    <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
          label="Loading..."
          class="align-middle">
          </b-spinner>
          <strong class=" ml-2">Fetching...</strong>
        </div>
      </template>
      <!-- Column: Game -->
      <template #cell(name)="data" >
        <span class="text-nowrap p-0">
          {{ data.item.name }}
        </span>
      </template>
      <!-- Column: Game Status -->
      <template #cell(is_offered)="data">
        Offered:
        <b-avatar
          :id="`game-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveGameStatusVariantAndIcon(data.item.is_offered).variant}`"
        >
          <feather-icon
            :icon="resolveGameStatusVariantAndIcon(data.item.is_offered).icon"
          />
        </b-avatar>
        <div class="mb-2">
          <span class="">
         Priority: <strong>{{ data.item.priority }}</strong>
        </span>
        </div>
        <b-tooltip
          :target="`game-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            Status: {{ data.item.is_offered }}
          </p>
          <p class="mb-0">
            Name: {{ data.item.name }}
          </p>
          <p class="mb-0">
          Slug: {{ data.item.slug }}
          </p>
          <p class="mb-0">
            Created at: {{ data.item.created_at }}
          </p>
        </b-tooltip>
      </template>
      <template #cell(tags)="data">
        <span class="text-nowrap">
          <div>
          <label for="tags-separators">
            Assigned Tags:
          </label>
            <b-badge
            :v-if='data.item.tags'
            class="ml-50"
            v-for="tag in data.item.tags.split(',')"
            :key="tag"
            variant="secondary"
            >
            {{ tag }}
          </b-badge>
          <div class="mb-2">
          <span class="text-nowrap" v-if="data.item.demo_url">
         <a :href="data.item.demo_url" target="_blank"><small class="text-warning">Open Demo</small></a>
        </span>
        </div>
        </div>
        </span>
      </template>
      <template #cell(thumbnail)="data">
        <span class="text-nowrap">
          <b-img
          left
          :id="`game-row-thumbnail${data.item.id}`"
          v-bind="thumbProps"
          fluid
          thumbnail
          :src="data.item.thumbnail"
          />
        </span>
        <div class="ml-2">
          <div class="mb-2 ml-2">
          <span class="ml-2 text-uppercase text-dark display-5">
         <strong>{{ data.item.name }}</strong>
        </span>
        </div>
        <div class="mb-2 ml-2">
          <span class="ml-2">
         Bets Recorded: <strong>{{ data.item.num_bets }}</strong>
        </span>
        </div>
        <div class="ml-2">
          <span class="ml-2">
         Aggregator: <strong>{{ data.item.aggregator }}</strong>
        </span>
        </div>
        <div class="ml-2">
          <span class="ml-2">
         Provider: <strong>{{ data.item.provider }}</strong>
        </span>
        </div>
      </div>
        <b-tooltip
          :target="`game-row-thumbnail${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            Width: {{ data.item.width }}
          </p>
          <p class="mb-0">
            Height: {{ data.item.height }}
          </p>
        </b-tooltip>
      </template>
      <!-- Column: Actions -->
        <template #cell(actions)="data">
          <span class="text-nowrap">
          <b-button
          pill variant="outline-warning"
          :to="{ name: 'apps-games-edit', params: { id: data.item.id } }"
          >
          More Actions
        </b-button>
      </span>
          
        </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalGames"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BImg,
  BBadge, BPagination, BTooltip, BButton, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useGamesList from './useGamesList'
import gameStoreModule from '../gameStoreModule'
import GameListAddNew from './GameListAddNew.vue'

export default {
  components: {
    GameListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BButton,
    BSpinner,
    BImg,
    vSelect,
  },
  data() {
      return {
        newTags: [],
        thumbProps: { blankColor: '#777', width: 150, height: 150, class: 'm1' }
      }
    },
  setup() {
    const GAMES_APP_STORE_MODULE_NAME = 'games'

    // Register module
    if (!store.hasModule(GAMES_APP_STORE_MODULE_NAME)) store.registerModule(GAMES_APP_STORE_MODULE_NAME, gameStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GAMES_APP_STORE_MODULE_NAME)) store.unregisterModule(GAMES_APP_STORE_MODULE_NAME)
    })

    const isAddNewGameSidebarActive = ref(false)
  
    const {
      fetchGames,
      tableColumns,
      perPage,
      currentPage,
      totalGames,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refGameListTable,

      statusFilter,
      isBusy,

      refetchData,

      resolveGameStatusVariantAndIcon,
      resolveGameAvatarVariant,
    } = useGamesList()

    return {
      fetchGames,
      tableColumns,
      perPage,
      currentPage,
      totalGames,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refGameListTable,
      isBusy,

      statusFilter,
      refetchData,
      isAddNewGameSidebarActive,
      avatarText,
      resolveGameStatusVariantAndIcon,
      resolveGameAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.game-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
